<template>
  <div>
    <el-form
      :model="form"
      status-icon
      ref="form"
      label-width="100px"
      class="directive-form"
      :rules="formRules"
    >
    <template v-for="f in eidtableFormItems">
      <el-form-item
        v-if="isShowItem(f)"
        :key="f.prop"
        :label="form.send_type === 'http' ? f.httpLabel || f.label : f.label"
        :prop="f.prop"
        :required="f.prop === 'instruction_code'|| f.prop === 'instruction_name'? true : undefined"
      >
        <el-select
          v-if="f.type === 'select'"
          v-model="form[f.prop]"
          placeholder="请选择"
          :disabled="isView"
          @change="selectChange(f.prop)"
        >
          <el-option
            v-for="item in formOptions[f.prop]"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-radio-group v-else-if="f.type === 'radio'" :disabled="isView" v-model="form[f.prop]">
          <el-radio
            v-for="item in formOptions[f.prop]"
            :key="item.value"
            :label="item.value"
          >{{item.label}}</el-radio>
        </el-radio-group>
        <div v-else-if="f.type === 'table'" class="table-div">
            <el-table
              :data="form[f.prop]"
              border
              style="width: 100%"
            >
              <el-table-column
                v-for="cell in editableCells"
                :key="cell.key"
                :prop="cell.key"
                :min-width="cell.width"
                :label="cell.label"
              >
                <template slot-scope="scope">
                  <el-button
                    v-if="cell.key === 'oper'"
                    @click="removeDatas(f.prop, scope.$index)"
                  >删除</el-button>
                  <el-input
                    v-else
                    :value="scope.row[cell.key]"
                    :placeholder="cell.placeholder"
                    @input="
                      (v) => {
                        onTableCellChange(v, scope.row, cell.key, f.prop);
                      }
                    "
                  ></el-input>
                </template>
              </el-table-column>
            </el-table>
            <el-button
              type="primary"
              @click="addDatas(f.prop)"
              class="table-add-btn"
            >添加</el-button>
        </div>
        <el-input
          v-else
          :disabled="isView"
          v-model="form[f.prop]"
          :type="f.type === 'textarea' ? 'textarea' : 'text'"
          :placeholder="f.placeholder"
        ></el-input>
      </el-form-item>
    </template>

      <el-form-item>
        <el-button v-if="!isView" type="primary" @click="submitForm()">提交</el-button>
        <el-button @click="backList()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { uuid, isEmpty } from '@/utils/utils';
export default {
  data() {
    let validateInstructionName = (rule, value, callback) => {
      if (value.trim() === '') {
        callback('请输入指令名称')
      } else {
        callback();
      }
    }
    let validateInstructionCode = (rule, value, callback) => {
      if (value.trim() === '') {
        callback(new Error(this.form.send_type === 'http' ? '请输入请求地址' : '请输入指令码'))
      } else {
        callback();
      }
    }
    return {
      form: this.initForm(),
      editableCells: [
        {
          label: "参数名",
          key: "name",
          placeholder: "请输入",
          width: "3",
        },
        {
          label: "参数值",
          key: "value",
          placeholder: "请输入",
          width: "2",
        },
        {
          label: "操作",
          key: "oper",
          width: "2",
        }
      ],
      eidtableFormItems: [
        {
          prop: "company_id",
          label: "公司名称",
          type: "select"
        },
        {
          prop: "public_type_id",
          label: "品牌型号",
          type: "select"
        },
        {
          prop: "send_type",
          label: "发送方式",
          type: "select"
        },
        {
          prop: "instruction_name",
          label: "指令名称",
          placeholder: "请输入",
        },
        {
          prop: "instruction_code",
          label: "指令码",
          httpLabel: '请求地址',
          placeholder: "请输入",
        },
        {
          prop: "return_code",
          label: "指令回码",
          placeholder: "请输入",
        },
        {
          prop: "unicode",
          label: "指令进制",
          placeholder: "请选择",
          type: "select"
        },
        {
          prop: "req_modes",
          label: "请求方式",
          type: "select"
        },
        {
          prop: "req_headers",
          label: "请求头",
          type: "table"
        },
        {
          prop: "param_type",
          label: "请求体类型",
          type: "radio"
        },
        {
          prop: "req_body",
          label: "请求体",
          type: "table"
        },
        {
          prop: "req_body_json",
          label: "请求体",
          type: "textarea",
          placeholder: "json格式",
        },
        {
          prop: "enter_space",
          label: "加入回车/空格",
          type: "radio"
        },
        {
          prop: "remark",
          label: "备注",
          type: "textarea",
          placeholder: "请输入备注",
        },
        {
          prop: "status",
          label: "是否启用",
          type: "radio"
        },
      ],
      httpKeys: ['param_type', 'req_body_json', 'req_modes', 'req_headers', 'req_body'],
      formOptions: {
        company_id: [],
        public_type_id: [],
        // send_type: [
        //   { label: 'tcpip', value: '1' },
        //   { label: 'udp', value: '2' }
        // ],
        send_type: [],
        req_modes: [
          { label: 'GET', value: '0' },
          { label: 'POST', value: '1' }
        ],
        unicode: [
          { label: '字符串', value: '1' },
          { label: '16进制', value: '2' }
        ],
        param_type: [
          { label: '键值对', value: '1' },
          { label: 'json格式', value: '0' },
        ],
        enter_space: [
          { label: '否', value: '0' },
          { label: '回车', value: '1' },
          { label: '空格', value: '2' },
          { label: '回车/换行', value: '3' },
          { label: '换行/回车', value: '4' }
        ],
        status: [
          { label: '是', value: 'true' },
          { label: '否', value: 'false' }
        ],
      },
      formRules: {
        company_id: [
          { required: true, message: `请选择公司名称`, trigger: "change" },
        ],
        public_type_id: [
          { required: true, message: "请选择型号名称", trigger: "change" },
        ],
        instruction_name: [
          { validator: validateInstructionName },
        ],
        instruction_code: [
          { validator: validateInstructionCode },
        ],
        req_modes: [
          { required: true, message: "请选择请求方式", trigger: "change" },
        ],
        param_type: [
          { required: true, message: "请选择请求头类型", trigger: "change" },
        ],
        req_headers: [
          { required: true, message: "请添加请求头", trigger: "change" },
        ],
        req_body_json: [
          { required: true, message: "请添加请求头", trigger: "change" },
        ],
        req_body: [
          { required: true, message: "请添加请求体", trigger: "change" },
        ]
      },
      httpRules: {
        instruction_code: [
          { required: true, message: '请输入请求地址', trigger: "blur" },
        ]
      },
      typeMap: {},
      isView: false
    };
  },
  watch: {
    directiveTreeDatas: function(val) {
      if (val && val.length > 0) {
        let componeyArr = [];
        let typeMap = {};
        val.map((item) => {
          if (item.children && item.children.length > 0) {
            item.children.map((companyItem) => {
              componeyArr.push({ label: companyItem.name, value: companyItem.id });
              typeMap[companyItem.id] = [];
              if (companyItem.children && companyItem.children.length > 0) {
                companyItem.children.map((typeItem) => {
                  typeMap[companyItem.id].push({ label: typeItem.name, value: typeItem.id });
                });
              }
            });
          }
        });
        this.formOptions.company_id = componeyArr;
        this.typeMap = typeMap;
      }
    }
  },
  computed: {
    ...mapGetters([
      'directiveTreeDatas'
    ]),
    isShowItem() {
      return (item) => {
        var isHttpShow = this.httpKeys.indexOf(item.prop) !== -1;
        if (isHttpShow) {
          if (item.prop === 'req_body') {
            return this.form.send_type === 'http'&&this.form.param_type === '1';
          } else if (item.prop === 'req_body_json') {
            return this.form.send_type === 'http'&&this.form.param_type === '0';
          }
          return this.form.send_type === 'http';
        }
        if (item.prop === 'unicode') {
          return this.form.send_type !== 'http';
        }
        // if (item.prop === 'instruction_code') {
        //   return this.form.send_type !== 'http';
        // }
        if (item.prop === 'enter_space') {
          return this.form.send_type !== 'http';
        }
        return true;
      }
    }
  },
  created() {
    this.getSendTypes();
  },
  async mounted() {
    if (this.$route.name === 'publicDirectiveView') {
      // 是否是查看
      this.isView = true;
    } else {
      this.isView = false;
    }
    await this.getTreeDatas();
    await this.getInitInfo();
  },
  methods: {
    ...mapActions([
      "getDirectiveTree",
      "findTableDataById",
      "addOrUpdateTableData"
    ]),
    addDatas(key) {
      this.form[key].push({
        name: '',
        value: '',
        id: uuid()
      });
    },
    removeDatas(formKey, i) {
      this.form[formKey].splice(i, 1);
    },
    onTableCellChange(v, row, valueKey, formKey) {
      const i = this.form[formKey].findIndex(
        (i) => i.id == row.id
      );
      this.form[formKey][i][valueKey] = v;
    },
    getSendTypes() {
      this.$changeLoadingState(true);
      this.$request.getTypeOptions({ type: "send_type" }).then((res) => {
        this.$changeLoadingState(false);
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        let datas = [];
        if (res && res.data && res.data.length !== 0) {
          res.data.map((item) => {
            datas.push({
              label: item.lable,
              value: item.value
            });
          });
        }
        this.formOptions.send_type = datas;
      }).catch(() => {
        this.$changeLoadingState(false);
      });
    },
    selectChange(key) {
      if (key === 'company_id') {
        // 切换公司下的型号
        const currentVal = this.form[key];
        this.formOptions.public_type_id = this.typeMap[currentVal];
        this.form.public_type_id = '';
      }
    },
    initForm() {
      return {
        id: null,
        company_id: '',
        public_type_id: '',
        instruction_name: '',
        instruction_code: '',
        return_code: '',
        unicode: '',
        send_type: '',
        req_modes: '',
        param_type: '1',
        req_headers: [],
        req_body_json: '',
        req_body: [],
        enter_space: '0',
        remark: '',
        status: 'true'
      };
    },
    async getTreeDatas() {
      try {
        this.$changeLoadingState(true);
        await this.getDirectiveTree();
      } catch (error) {
        console.log(error);
      }
      this.$changeLoadingState(false);
    },
    async getInitInfo() {
      const { id } = this.$route.query;
      if (!id) return;
      const ret = await this.findTableDataById({ id });
      if (ret && ret.status === 'success') {
        const result = ret.data || {};
        Object.keys(this.form).forEach((key) => {
          if (result.hasOwnProperty(key)) {
            let val = result[key];
            if (key === 'req_body' && result.param_type === '0') {
              // json类型的请求头
              this.form.req_body_json = val;
            } else {
              if (key === 'req_headers' || key === 'req_body') {
                // 值需要转换一下
                if (result[key]) {
                  let currentVal = JSON.parse(result[key]);
                  val = Object.keys(currentVal).reduce((arr, item) => {
                    arr.push({
                      name: item,
                      value: currentVal[item],
                      id: uuid()
                    });
                    return arr;
                  }, [])
                } else {
                  val = [];
                }
              }
              this.form[key] = val;
            }
          }
        });
        if (isEmpty(result.param_type)) {
          // 默认请求类型为键值对
          this.form.param_type = '1';
        }
        if (result.company_id) {
          this.formOptions.public_type_id = this.typeMap[result.company_id];
        }
      } else {
        this.$message.error(ret && ret.msg);
      }
    },
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          // 验证请求头和请求体
          if (this.form.send_type === 'http') {
            if (this.form.param_type === '0') {
              var isNumber = !isNaN(Number(this.form.req_body_json));
              if (!isNumber) {
                try {
                  JSON.parse(this.form.req_body_json);
                } catch (error) {
                  this.$message.error('请求体请填写正确的json格式');
                  return;
                }
              }
            } else {
              let isEmpty = false;
              for (let k = 0;k < this.form.req_headers.length;k++) {
                if (!this.form.req_headers[k].name || !this.form.req_headers[k].value) {
                  isEmpty = true;
                }
              }
              if (isEmpty) {
                this.$message.error('请求头中存在空的值，请检查并补全');
                return;
              }
            }
            let isBodyEmpty = false;
            for (let k = 0;k < this.form.req_body.length;k++) {
              if (!this.form.req_body[k].name || !this.form.req_body[k].value) {
                isBodyEmpty = true;
              }
            }
            if (isBodyEmpty) {
              this.$message.error('请求体中存在空的值，请检查并补全');
              return;
            }
          }

          let req_headers = '';
          if (this.form.req_headers.length !== 0) {
            req_headers = JSON.stringify(this.form.req_headers.reduce((obj, item) => {
              obj[item.name] = item.value;
              return obj;
            }, {}));
          }
          let req_body = '';
          if (this.form.param_type === '0') {
            req_body = this.form.req_body_json;
          } else {
            if (this.form.req_body.length !== 0) {
              req_body = JSON.stringify(this.form.req_body.reduce((obj, item) => {
                obj[item.name] = item.value;
                return obj;
              }, {}));
            }
          }
          let params = {
            ...this.form,
            req_headers: req_headers,
            req_body: req_body
          };
          delete params.req_body_json;
          const ret = await this.addOrUpdateTableData(params);
          if (ret && ret.status === 'success') {
            this.backList();
          } else {
            this.$message.error(ret && ret.msg);
          }
        }
      });
    },
    backList() {
      let url = "/publicResource/publicDirective";
      const { company_id, public_type_id } = this.$route.query;
      let queryArr = [];
      if (company_id) {
        queryArr.push('company_id=' + company_id);
      }
      if (public_type_id) {
        queryArr.push('public_type_id=' + public_type_id);
      }
      if (queryArr.length !== 0) {
        url = url + '?' + queryArr.join('&');
      }
      this.$router.push(url);
    }
  },
};
</script>

<style lang='scss'>
.directive-form {
  background-color: #fff;
  padding: 24px 30% 24px 24px;
  .el-cascader--small {
    width: 100%;
  }
  .el-form-item__label {
    width: 30% !important;
  }
  .el-form-item__content {
    margin-left: 30% !important;
    .el-select {
      display: block;
    }
  }
}
</style>
<style lang="scss" scoped>
.table-div {
  display: flex;
  .table-add-btn {
    flex-grow: 0;
    margin-left: 10px;
    height: 32px;
  }
}
</style>